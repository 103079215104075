<template>
  <b-card title="Makine Bilgisi">
    <b-list-group>
      <list-item
        v-if="machine.project"
        label="Proje Numarası"
        :value="machine.project"
      />
      <list-item
        v-if="machine.serial"
        label="Fabrika / Seri No"
        :value="machine.serial"
      />
      <list-item
        v-if="machine.syear"
        label="Kurulum Yılı"
        :value="machine.syear"
      />
      <list-item
        v-if="machine.machine_type"
        label="Makine Tipi"
        :value="machine.machine_type"
      />
      <list-item
        v-if="machine.machine_model"
        label="Makine Modeli"
        :value="machine.machine_model"
      />
      <list-item
        v-if="machine.machine_cleaning"
        label="Temizleme Teknolojisi"
        :value="machine.machine_cleaning"
      />
      <list-item
        v-if="machine.machine_status"
        label="Makine Durumu"
        :value="machine.machine_status"
      />
      <list-item
        v-if="machine.notes"
        label="Notlar"
        :value="machine.notes"
      />
      <list-item
        v-if="machine.created"
        label="Oluşturma"
        :value="machine.username + ' ' + moment(machine.created).format('DD.MM.YYYY HH:mm')"
      />
      <list-item
        v-if="machine.modified"
        label="Güncelleme"
        :value="machine.username + ' ' + moment(machine.modified).format('DD.MM.YYYY HH:mm')"
      />
    </b-list-group>
    <div class="text-center">
      <b-button
        :to="'/app/service_requests/add/' + machine.id"
        variant="primary"
        class="mt-1"
      >
        Servis Talebi Oluştur
      </b-button>
    </div>
  </b-card>
</template>

<script>
import { BCard, BListGroup, BButton } from 'bootstrap-vue'
// eslint-disable-next-line import/extensions
import ListItem from '@/views/Machines/View/ListItem'

export default {
  name: 'Machine',
  components: {
    BCard,
    BListGroup,
    BButton,
    ListItem,
  },
  computed: {
    machine() {
      return this.$store.getters['machines/getMachines']
    },
  },
}
</script>

<style scoped>

</style>
